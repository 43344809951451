import { Button } from "@sgbs-ui/core";
import React from "react";

export interface Props {
    message: string;
    detailedMessage?: string;
}

export const ErrorMessage: React.FunctionComponent<Props> = ({ message, detailedMessage }) => {
    const [showMore, setShowMore] = React.useState<boolean>(false);

    const displayButtonText = () => {
        return showMore ? "Hide" : "Show more"
    }
    return (
        <>
            <div>
                {message}
            </div>
            {detailedMessage ? <Button
                className="btn-sm btn-primary mt-2 mb-2"
                text={displayButtonText()}
                onClick={() => setShowMore(!showMore)}
            /> : <></>}
            {showMore ? <div>{detailedMessage}</div> : <></>}
        </>
    );

};
